import React, { useState } from 'react'
import './UF.css'

const PA = () => {
  const [service] = useState([
    { id: 1, uf: 'PA', city: 'Belém', name: 'Clinica de Psicologia da UFPA', address: 'Setor Básico do campus do Guamá ao lado do Ginásio de Esportes da Universidade Federal do Pará. Entrada pelo portão 2 do campus, primeiro prédio à esquerda.', phone: '(91) 3201-7669', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'PA', city: 'Ananindeua', name: 'Clínica de Psicologia da FAAM', address: '-', phone: '(91) 8875-7310', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'PA', city: 'Belém', name: 'Clínica de Psicologia (CLIPSI) da Universidade da Amazônia', address: 'Campus Alcindo Cacela - Bloco F - 1º andar', phone: '(91) 4009-3012', type: 'Clínica escola', info: 'psico@unama.br' },
    { id: 4, uf: 'PA', city: 'Belém', name: 'Clinica de Psicologia (CEPSI) da UNIFAMAZ', address: '-', phone: '(91) 3201-0394', type: 'Clínica escola', info: 'clinicapsicologia@famaz.com.br' },
    { id: 5, uf: 'PA', city: 'Belém', name: 'Clínica de Psicologia do Cesupa', address: 'Campus Cesupa Nazaré (Av. Nazaré, 630, entre Rui Barbosa e Quintino Bocaiúva)', phone: '(91) 4009-2157', type: 'Clínica escola', info: 'clinicapsi@cesupa.br' },
    { id: 6, uf: 'PA', city: 'Belém', name: 'Clínica de Psicologia - Estácio Belém', address: 'Av. Generalíssimo Deodoro, 1130 - Nazaré', phone: '-', type: 'Clínica escola', info: '-' },
    { id: 7, uf: 'PA', city: 'Santarém', name: 'Clínica-escola de Psicologia do IESPES', address: 'Coaraci Nunes, 3344 - Caranazal', phone: '(93) 99197-6219', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },


  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Pará</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default PA
