import React, { useState } from 'react'
import './UF.css'

const SE = () => {
  const [service] = useState([
    { id: 1, uf: 'SE', city: 'São Cristóvão', name: 'Serviço de Psicologia Aplicada da Universidade Federal de Sergipe', address: 'Campus São Cristóvão', phone: '(79) 3194-7261 | (79) 3194-7276', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'SE', city: 'Aracaju', name: 'Clínica de Psicologia - Centro de Apoio Psicossocial', address: 'Universidade Tiradentes (Unit) - Avenida Murilo Dantas, 54, bairro Farolândia', phone: '(79) 3218-2212 | (79) 3218-2213', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'SE', city: 'Aracaju', name: 'Centro de Práticas em Psicologia - Faculdade Pio Décimo', address: 'Av. Augusto Franco, 2715 - Ponto Novo', phone: '(79) 99998-0970', type: 'Clínica escola', info: '-' },
    { id: 4, uf: 'SE', city: 'Aracaju', name: 'Clínica de Psicologia da FSLF', address: 'Centro de Educação e Saúde Ninota Garcia - Avenida João Rodrigues, 200, Bairro Industrial', phone: '(79) 3215-5137', type: 'Clínica escola', info: '-' },
    { id: 5, uf: 'SE', city: 'Aracaju', name: 'Serviço Aplicado à Psicologia (SPA)', address: 'Centro Universitário Estácio de Sergipe - Rua Teixeira de Freitas, 10 - Salgado Filho', phone: '(79) 3246-8149', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Sergipe</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default SE
