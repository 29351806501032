import React, { useState } from 'react'
import './UF.css'

const AM = () => {
  const [service] = useState([
    { id: 1, uf: 'AM', city: 'Manaus', name: 'Centro de Saúde Mental do Amazonas (CESMAM)', address: 'Av. Des. João Machado, S/N - Planalto', phone: '(92) 98414-6638 (Recepção Geral) | (92) 98414-4146 (Serviço Social)', type: 'Serviço público', info: 'Atendimento 24h | servicosocial.cesman@saude.am.gov.br' },
    { id: 2, uf: 'AM', city: 'Manaus', name: 'Clínica Escola de Psicologia da ESBAM', address: 'Rua Rodrigues Torres, 292, conjunto Abílio Nery, Adrianópolis, por trás do Hipermercado DB', phone: '(92) 3305-1809 | (92) 98459-9407', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'AM', city: 'Manaus', name: 'Clínica de Psicologia UNIP Manaus', address: 'Rua 33 - Casa 12 - Conjunto Castelo Branco - Parque 10', phone: '(92) 3632-2503', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },

  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Amazonas</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default AM
