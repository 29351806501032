import '../App.css';
import logopsicotaon from '../assets/logopsicotaon.png';
import Search from './Search';
import Social from './Social';
import { BsEmojiSmileUpsideDown } from "react-icons/bs";

const Main = () => {
  return (
    <>
    <img id='logo' src={logopsicotaon} alt="Imagem circular com o nome do site em cores pastéis" />
    <br />
    <h1>Seja bem-vinda(o)!</h1>
    <article id="main">
      <h2>Busque por serviços de saúde mental próximos a você <BsEmojiSmileUpsideDown /></h2>
      <p id='presentation'>
    Encontre informações sobre clínicas-escolas e serviços públicos, além de serviços similares que disponham de atendimento psicológico e/ou psiquiátrico gratuitos ou a preços acessíveis.
    <br /><br />
    Os dados são atualizados periodicamente. Para comunicar erros ou indicar serviços que não estejam listados, clique <a href='https://forms.gle/jCkrLN2tiKhXGKieA' target='_blank' rel='noreferrer'>aqui</a> ou envie um e-mail para <a href='mailto:contato@psicotaon.com.br'>contato@psicotaon.com.br</a>
      </p>
    </article>
    <Social />
    <br />
    <Search />
    </>
  )
}

export default Main