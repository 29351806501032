import styles from './Social.module.css';
import { FaInstagram } from "react-icons/fa";
// import { FaTiktok } from "react-icons/fa";
// import { FaTwitch } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaRegEnvelope } from "react-icons/fa";

const Social = () => {
  return (
    <div className={styles.social}>
        <a href="https://www.instagram.com/psicotaon/" target="_blank" rel="noreferrer"><FaInstagram /></a>        
        <a href="https://twitter.com/psicotaon" target="_blank" rel="noreferrer"><FaTwitter /></a>
        <a href="mailto:contato@psicotaon.com.br"><FaRegEnvelope /></a>
    </div>
  )
}

// <a href="https://www.tiktok.com/@psicotaon" target="_blank" rel="noreferrer"><FaTiktok /></a>
// <a href="https://www.twitch.tv/psicotaon" target="_blank" rel="noreferrer"><FaTwitch /></a>

export default Social