import React, { useState } from 'react'
import './UF.css'

const RN = () => {
  const [service] = useState([
    { id: 1, uf: 'RN', city: 'Natal', name: 'Serviço de Psicologia Aplicada - SEPA/Natal', address: 'Rua da Biblioteca Zila Mamede (BCZM), Campus Central', phone: '(84) 3342-2234', type: 'Clínica escola', info: 'atendimentosepanatal@gmail.com' },
    { id: 2, uf: 'RN', city: 'Santa Cruz', name: 'Serviço Escola de Psicologia Aplicada (SEPA)', address: 'ANEXO I da FACISA (antiga escola Miguel Lula) Av. Rio Branco, 571, Centro', phone: '(84) 99480-6886', type: 'Clínica escola', info: 'sepa.facisa@gmail.com' },
    { id: 3, uf: 'RN', city: 'Natal', name: 'Serviço-Escola de Psicologia (SEP) do UNIFACEX', address: 'Rua Doutor José Xavier da Cunha, nº 1978, Capim Macio', phone: '(84) 3235-1415 Ramal: 302', type: 'Clínica escola', info: 'seppsicologia@unifacex.edu.br | psicologia@unifacex.edu.br' },
    { id: 4, uf: 'RN', city: 'Natal', name: 'Clínicas Integradas do UNI-RN', address: 'Rua Prefeita Eliane Barros, 2000, Tirol (rua lateral à Escola Doméstica)', phone: '(84) 3215-2960', type: 'Clínica escola', info: '-' },
    //    { id: 2, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 2, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 2, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Rio Grande do Norte</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default RN
