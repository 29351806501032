import React, { useState } from 'react'
import './UF.css'

const RR = () => {
  const [service] = useState([
    { id: 1, uf: 'RR', city: 'Boa Vista', name: 'Serviço de Atendimento Psicológico (SAP) da UFRR', address: 'Campus Paricarana, Zona Norte', phone: '(95) 98113-0623', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'RR', city: 'Boa Vista', name: 'Clínica-Escola de Psicologia', address: 'Faculdade Cathedral - Avenida Luís Canuto Chaves, 293, Caçari', phone: '(95) 2121-3473', type: 'Clínica escola', info: 'clinicapsicologia@cathedral.edu.br' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Roraima</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default RR
