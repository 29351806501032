import React, { useState } from "react";
import './location/UF.css'
import AC from './location/AC'
import AL from './location/AL'
import AP from './location/AP'
import AM from './location/AM'
import BA from './location/BA'
import CE from './location/CE'
import DF from './location/DF'
import ES from './location/ES'
import GO from './location/GO'
import MA from './location/MA'
import MT from './location/MT'
import MS from './location/MS'
import MG from './location/MG'
import PA from './location/PA'
import PB from './location/PB'
import PR from './location/PR'
import PE from './location/PE'
import PI from './location/PI'
import RJ from './location/RJ'
import RN from './location/RN'
import RS from './location/RS'
import RO from './location/RO'
import RR from './location/RR'
import SC from './location/SC'
import SP from './location/SP'
import SE from './location/SE'
import TO from './location/TO'
import AO from './location/AO'

function Search() {
  const [selectedOption, setSelectedOption] = useState("");
  const [showComponent, setShowComponent] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setShowComponent(true);
  };

  return (
    <div>
      <label htmlFor="select">Selecione seu estado:</label><br />
      <select id="select" value={selectedOption} onChange={handleOptionChange}>
        <option value="">Estado</option>
        <option value="option0">Atendimento Online</option>
        <option value="option1">Acre</option>
        <option value="option2">Alagoas</option>
        <option value="option3">Amapá</option>
        <option value="option4">Amazonas</option>
        <option value="option5">Bahia</option>
        <option value="option6">Ceará</option>
        <option value="option7">Distrito Federal</option>
        <option value="option8">Espírito Santo</option>
        <option value="option9">Goiás</option>
        <option value="option10">Maranhão</option>
        <option value="option11">Mato Grosso</option>
        <option value="option12">Mato Grosso do Sul</option>
        <option value="option13">Minas Gerais</option>
        <option value="option14">Pará</option>
        <option value="option15">Paraíba</option>
        <option value="option16">Paraná</option>
        <option value="option17">Pernambuco</option>
        <option value="option18">Piauí</option>
        <option value="option19">Rio de Janeiro</option>
        <option value="option20">Rio Grande do Norte</option>
        <option value="option21">Rio Grande do Sul</option>
        <option value="option22">Rondônia</option>
        <option value="option23">Roraima</option>
        <option value="option24">Santa Catarina</option>
        <option value="option25">São Paulo</option>
        <option value="option26">Sergipe</option>
        <option value="option27">Tocantins</option>
      </select>

      {showComponent && selectedOption === "option0" && <AO />}
      {showComponent && selectedOption === "option1" && <AC />}
      {showComponent && selectedOption === "option2" && <AL />}
      {showComponent && selectedOption === "option3" && <AP />}
      {showComponent && selectedOption === "option4" && <AM />}
      {showComponent && selectedOption === "option5" && <BA />}
      {showComponent && selectedOption === "option6" && <CE />}
      {showComponent && selectedOption === "option7" && <DF />}
      {showComponent && selectedOption === "option8" && <ES />}
      {showComponent && selectedOption === "option9" && <GO />}
      {showComponent && selectedOption === "option10" && <MA />}
      {showComponent && selectedOption === "option11" && <MT />}
      {showComponent && selectedOption === "option12" && <MS />}
      {showComponent && selectedOption === "option13" && <MG />}
      {showComponent && selectedOption === "option14" && <PA />}
      {showComponent && selectedOption === "option15" && <PB />}
      {showComponent && selectedOption === "option16" && <PR />}
      {showComponent && selectedOption === "option17" && <PE />}
      {showComponent && selectedOption === "option18" && <PI />}
      {showComponent && selectedOption === "option19" && <RJ />}
      {showComponent && selectedOption === "option20" && <RN />}
      {showComponent && selectedOption === "option21" && <RS />}
      {showComponent && selectedOption === "option22" && <RO />}
      {showComponent && selectedOption === "option23" && <RR />}
      {showComponent && selectedOption === "option24" && <SC />}
      {showComponent && selectedOption === "option25" && <SP />}
      {showComponent && selectedOption === "option26" && <SE />}
      {showComponent && selectedOption === "option27" && <TO />}

    </div>
  );
}

export default Search;
