import React, { useState } from 'react'
import './UF.css'

const AC = () => {
  const [service] = useState([
    { id: 1, uf: 'AC', city: 'Rio Branco', name: 'Serviço Escola de Psicologia (SERPSI)', address: 'UFAC - Campus Universitário - BR 364, Km 4 - Distrito Industrial', phone: '-', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'AC', city: 'Rio Branco', name: 'Clínica de Psicologia Uninorte', address: 'Alameda Alemanha, 200 - Bairro Jardim Europa - Bloco D no 2° Andar', phone: '(68) 3302-7044', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Acre</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default AC
