import React, { useState } from 'react'
import './UF.css'

const SP = () => {
  const [service] = useState([
    { id: 1, uf: 'SP', city: 'São Paulo', name: 'Centro Escola do Instituto de Psicologia da USP (CEIP)', address: 'Av. Prof Mello Moraes, 1721 - Bloco D - Cidade Universitária', phone: '(11) 3091-8248 | (11) 3091-8223', type: 'Clínica escola', info: 'ceip@usp.br' },
    { id: 2, uf: 'SP', city: 'São Paulo', name: 'Clínica Psicológica “Ana Maria Poppovic” da PUC-SP', address: 'Rua Almirante Pereira Guimarães, 150 - Pacaembu', phone: '(11) 3862-6070', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'SP', city: 'São Paulo', name: 'Serviço-Escola de Psicologia Mackenzie', address: 'Rua Piauí, 181 - 2ª andar - Higienópolis', phone: '(11) 2114-8342', type: 'Clínica escola', info: 'ccbs.clinicapsicologia@mackenzie.br' },
    //    { id: 4, uf: 'SP', city: '', name: '', address: '', phone: '', type: '', info: '' },
    { id: 5, uf: 'SP', city: 'Santo André', name: 'Clínica-Escola da Fundação Santo André', address: 'Rua Vicente de Carvalho, nº 142, Campus da FSA', phone: '(11) 4979-3458', type: 'Clínica escola', info: 'coepp@fsa.br' },
    { id: 6, uf: 'SP', city: 'São Paulo', name: 'Clínica Escola de Psicologia da FMU', address: 'Telefone e e-mail para agendamento', phone: '(11) 3346-6217 | (11) 3346-6216', type: 'Clínica escola', info: 'ciecs@fmu.br' },
    { id: 7, uf: 'SP', city: 'São Paulo', name: 'Clínica Escola de Psicologia da FMU - Campus Santo Amaro', address: 'Avenida Santo Amaro, 1239 - Vila Nova Conceição', phone: '(11) 3040-3400 - Ramal 2316', type: 'Clínica escola', info: 'clinicapsico@fmu.br' },
    { id: 8, uf: 'SP', city: 'São Paulo', name: 'Clínica Escola de Psicologia da FMU - Campus Liberdade', address: 'Avenida da Liberdade, 899 - Liberdade', phone: '(11) 3346-6200 - Ramal 7237', type: 'Clínica escola', info: 'clinicapsico.liberdade@fmu.br' },
    { id: 9, uf: 'SP', city: 'São Paulo', name: 'Clínica de Psicologia da Universidade Metodista', address: '-', phone: '(11) 4366-5300 | (11) 4366-5396', type: 'Clínica escola', info: '-' },
    { id: 10, uf: 'SP', city: 'São Paulo', name: 'Clínica-Escola de Psicologia da UNICID', address: 'Rua Antônio de Barros, 602 (ao lado do Viaduto Antônio Abdo)', phone: '(11) 2178-1596', type: 'Clínica escola', info: '-' },
    { id: 11, uf: 'SP', city: 'Campinas', name: 'Serviço-Escola de Psicologia da PUC-Campinas', address: 'Prédio do Ambulatório de Especialidades, no Campus II da PUC-Campinas na Avenida John Boyd Dunlop, s/n, Jardim Ipaussurama', phone: '(19) 3343-6846 | (19) 97166-5474 (WhatsApp)', type: 'Clínica escola', info: '-' },
    { id: 12, uf: 'SP', city: 'Barueri', name: 'Clínica de Psicologia UNIP Alphaville', address: 'Alameda Amazonas, 492 - Alphaville', phone: '(11) 4191-1078', type: 'Clínica escola', info: '-' },
    { id: 13, uf: 'SP', city: 'Araçatuba', name: 'Clínica de Psicologia UNIP Araçatuba', address: 'Av. Baguaçu, 1.939 - Jardim Alvorada', phone: '(18) 3117-4586', type: 'Clínica escola', info: '-' },
    { id: 14, uf: 'SP', city: 'Araraquara', name: 'Clínica de Psicologia UNIP Araraquara', address: 'Av. Alberto Benassi, 200 - Parque das Laranjeiras', phone: '(16) 3336-1800 - Ramal 145', type: 'Clínica escola', info: '-' },
    { id: 15, uf: 'SP', city: 'Assis', name: 'Clínica de Psicologia UNIP Assis', address: 'Av. Otto Ribeiro, 2.378 - Jardim Canadá', phone: '(18) 3321-5110', type: 'Clínica escola', info: '-' },
    { id: 16, uf: 'SP', city: 'Bauru', name: 'Clínica de Psicologia UNIP Bauru', address: 'Rua Luiz Levorato, 2-14 - Jardim Marabá', phone: '(14) 3312-7018', type: 'Clínica escola', info: '-' },
    { id: 17, uf: 'SP', city: 'Campinas', name: 'Clínica de Psicologia UNIP Campinas', address: 'Av. Comendador Enzo Ferrari, 280 - Swift', phone: '(19) 3776-4001', type: 'Clínica escola', info: '-' },
    { id: 18, uf: 'SP', city: 'São Paulo', name: 'Clínica de Psicologia UNIP Chácara Santo Antônio', address: 'Rua da Paz, 704 - Alto da Boa Vista', phone: '(11) 5181-1441 | (11) 5184-2038', type: 'Clínica escola', info: '-' },
    { id: 19, uf: 'SP', city: 'Jundiaí', name: 'Clínica de Psicologia UNIP Jundiaí', address: 'Av. Armando Giassetti, 577 - Vila Hortolândia - Trevo Itu / Itatiba', phone: '(11) 4815-2333 - Ramais 143 e 148', type: 'Clínica escola', info: '-' },
    { id: 20, uf: 'SP', city: 'Limeira', name: 'Clínica de Psicologia UNIP Limeira', address: 'Rua Miguel Guidotti, 405 - Parque Egisto Ragazzo', phone: '(19) 3701-7063', type: 'Clínica escola', info: '-' },
    { id: 21, uf: 'SP', city: 'São Paulo', name: 'Clínica de Psicologia UNIP Marquês', address: 'Av. Comendador Martinelli, 72 - Vila Chalot', phone: '(11) 3611-3030', type: 'Clínica escola', info: '-' },
    { id: 22, uf: 'SP', city: 'São Paulo', name: 'Clínica de Psicologia UNIP Norte', address: 'Rua Amazonas da Silva, 737 - Vila Guilherme', phone: '(11) 2790-1550 - Ramal 1520', type: 'Clínica escola', info: '-' },
    { id: 23, uf: 'SP', city: 'Ribeirão Preto', name: 'Clínica de Psicologia UNIP Ribeirão Preto', address: 'Rua Mariana Junqueira, 1.346', phone: '(16) 3632-2656 | (16) 3632-6401', type: 'Clínica escola', info: '-' },
    { id: 24, uf: 'SP', city: 'Santos', name: 'Clínica de Psicologia UNIP Santos', address: 'Av. Ana Costa, 63/65 - Vila Mathias', phone: '(13) 3224-9890', type: 'Clínica escola', info: '-' },
    { id: 25, uf: 'SP', city: 'São José dos Campos', name: 'Clínica de Psicologia UNIP São José dos Campos', address: 'Rodovia Presidente Dutra, KM 157,5 - Limoeiro', phone: '(12) 2136-9000 | (12) 2136-9069 | (12) 2136-9070', type: 'Clínica escola', info: '-' },
    { id: 26, uf: 'SP', city: 'São José do Rio Pardo', name: 'Clínica de Psicologia UNIP São José do Rio Pardo', address: 'Av. Deputado Eduardo Vicente Nasser, 894 - Centro', phone: '(19) 3681-2655', type: 'Clínica escola', info: '-' },
    { id: 27, uf: 'SP', city: 'São José do Rio Preto', name: 'Clínica de Psicologia UNIP São José do Rio Preto', address: 'Av. Juscelino K de Oliveira - s/n - Jardim Tarraf', phone: '(17) 2137-5000', type: 'Clínica escola', info: '-' },
    { id: 28, uf: 'SP', city: 'Sorocaba', name: 'Clínica de Psicologia UNIP Sorocaba', address: 'Av. Independência, 210 - Iporanga', phone: '(15) 3412-1091', type: 'Clínica escola', info: '-' },
    { id: 29, uf: 'SP', city: 'São Paulo', name: 'Clínica de Psicologia UNIP Tatuapé', address: 'Rua Antonio de Macedo, 505 - Tatuapé', phone: '(11) 2090-4518', type: 'Clínica escola', info: '-' },
    { id: 30, uf: 'SP', city: 'São Paulo', name: 'Clínica de Psicologia UNIP Vergueiro', address: 'Rua Apeninos, 614 - Aclimação', phone: '(11) 2166-1160', type: 'Clínica escola', info: '-' },
    { id: 31, uf: 'SP', city: 'São Paulo', name: 'Clínica de Psicologia São Camilo', address: 'R. Raul Pompéia, 144 - Pompéia', phone: '(11) 3355-3929', type: 'Clínica escola', info: 'agendamento.psicologia@saocamilo-sp.br' },
    { id: 32, uf: 'SP', city: 'São Paulo', name: 'Clínica Escola - Promove São Camilo', address: 'Rua Eng. Ranulfo Pinheiro de Lima, 200', phone: '(11) 3206-9600', type: 'Clínica escola', info: 'coordpromove@saocamilo-sp.br' },
    //    { id: 32, uf: 'SP', city: '', name: '', address: '', phone: '', type: '', info: '' },

  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
      <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>São Paulo</h3>
      <p>
      <ul>
        {filteredService.map((item) => (
          <li key={item.id}>
            <strong>{item.name}</strong><br />
            <strong>UF: </strong>{item.uf}<br />
            <strong>Cidade: </strong>{item.city}<br />
            <strong>Endereço: </strong>{item.address}<br />
            <strong>Telefone: </strong>{item.phone}<br />
            <strong>Tipo: </strong>{item.type}<br />
            <strong>Mais Informações: </strong>{item.info}<br />
            <hr />
          </li>
        ))}
      </ul>
      </p>
    </div>
  )
}

export default SP
