import React, { useState } from 'react'
import './UF.css'

const TO = () => {
  const [service] = useState([
    { id: 1, uf: 'TO', city: 'Palmas', name: 'CEULP/ULBRA - Clínica de Psicologia', address: 'AV. JK, Quadra 108 Norte, Alameda 12, Al 10 Plano Diretor Norte', phone: '(63) 3223-2015', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'TO', city: 'Gurupi', name: 'UnirG - Serviço Escola de Psicologia (Sepsi)', address: 'Avenida Guanabara, esquina com a Rua 9, ao lado do Campus II da UnirG', phone: '(63) 3612-7717', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'TO', city: 'Araguaína', name: 'Clínica-Escola de Psicologia da Católica Orione', address: 'Rua Santa Cruz, nº 557, Centro de Araguaína', phone: '(63) 3513-0500', type: 'Clínica escola', info: 'clinicaescola@catolicaorione.edu.br' },
    { id: 4, uf: 'TO', city: 'Miracema', name: 'Centro de Estudos e Práticas em Psicologia - Cepsi', address: 'Universidade Federal do Tocantins - Campus Warã - Av. Lourdes Solino, 195 - St. Sussuapara', phone: '-', type: 'Clínica escola', info: 'cepsi.uft@gmail.com' },
    { id: 5, uf: 'TO', city: 'Araguaína', name: 'Clínica-Escola de Psicologia do UNITPAC', address: 'Av. Filadélfia, 568 - St. Oeste', phone: '(63) 3411-8536', type: 'Clínica escola', info: '-' },
    { id: 6, uf: 'TO', city: 'Palmas', name: 'Clínica Escola de Psicologia da UNINASSAU', address: 'Sede da UNINASSAU - 106 sul', phone: '-', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Tocantins</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default TO
