import React, { useState } from 'react'
import './UF.css'

const GO = () => {
  const [service] = useState([
    { id: 1, uf: 'GO', city: 'Goiânia', name: 'Centro de Psicologia da UFG', address: 'Rua 235, nº 575 - Setor Leste Universitário', phone: '(62) 3209-6298', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'GO', city: 'Goiânia', name: 'Centro de Estudos, Pesquisas e Práticas Psicológicas (CEPSI)', address: 'Rua 232, nº 128, 2º andar, Área V da PUC Goiás - Setor Leste Universitário', phone: '(62) 3946-1198 | (62) 3946-1249', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'GO', city: 'Goiânia', name: 'Clínica-Escola Vida', address: 'Rua Colônia Qd. 240C, Lts. 28 e 29 - Jardim Novo Mundo', phone: '(62) 3946-1808', type: 'Clínica escola', info: '-' },
    { id: 4, uf: 'GO', city: 'Goiânia', name: 'Núcleo de Psicologia Aplicada - Universo Goiânia', address: 'Avenida Cora Coralina, Quadra F 25 Lote 40 - Setor Sul, 4º andar, Bloco A', phone: '(62) 3238-3719', type: 'Clínica escola', info: '-' },
    { id: 5, uf: 'GO', city: 'Goiânia', name: 'Núcleo de Estudos, Pesquisas e Práticas Psicológicas (NEP) da UNIALFA', address: '-', phone: '(62) 3272-5089', type: 'Clínica escola', info: '-' },
    { id: 6, uf: 'GO', city: 'Rio Verde', name: 'Clínica Escola de Psicologia (CLIEP)', address: 'Rua Nivaldo Ribeiro nº 40 - Centro', phone: '(64) 3623-8097', type: 'Clínica escola', info: 'UniRV' },
    { id: 7, uf: 'GO', city: 'Goiânia', name: 'Clínica de Psicologia UNIP Goiânia', address: 'BR-153 - s/n - Áreas 01-05 - Km 503 - Bloco D2 - Fazenda Botafogo', phone: '(62) 3281-8581', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },

  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Goiás</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default GO
