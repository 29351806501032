import React, { useState } from 'react'
import './UF.css'

const RJ = () => {
  const [service] = useState([
    { id: 1, uf: 'RJ', city: 'Rio de Janeiro', name: 'Divisão de Psicologia Aplicada Prof.ª Isabel Adrados - DPA', address: 'Avenida Pasteur, 250 - Pavilhão Nilton Campos - UFRJ - Campus da Praia Vermelha', phone: '(21) 2295-8113', type: 'Clínica escola', info: 'dpa@psicologia.ufrj.br | divisao.de.psicologia.ufrj@gmail.com' },
    { id: 2, uf: 'RJ', city: 'Niterói', name: 'Serviço de Psicologia Aplicada (SPA)', address: 'Rua Professor Marcos Waldemar de Freitas Reis, s/n, Campus do Gragoatá, Bloco N, 5º andar - São Domingos', phone: '(21) 2629-2300', type: 'Clínica escola', info: 'spa.ips@id.uff.br' },
    { id: 3, uf: 'RJ', city: 'Rio de Janeiro', name: 'Serviço de Psicologia Aplicada (SPA) do Instituto de Psicologia da UERJ', address: 'Secretaria do SPA - Rua São Francisco Xavier, 524, sala 10.006, bloco D, 10° andar, Maracanã', phone: '(21) 2334-0108', type: 'Clínica escola', info: 'contato@institutodepsicologiauerj.org' },
    { id: 4, uf: 'RJ', city: 'Rio de Janeiro', name: 'Serviço de Psicologia Aplicada (SPA) do Departamento de Psicologia da PUC-Rio', address: '-', phone: '(21) 3527-1574 | (21) 3527-1575 | (21) 99328-2539 (WhatsApp)', type: 'Clínica escola', info: '-' },
    { id: 5, uf: 'RJ', city: 'Rio de Janeiro', name: 'Clínica de Psicologia Social', address: 'Cruz Vermelha Brasileira - Praça da Cruz Vermelha 10/12 - Centro', phone: '(21) 2508-9090 ou ramal 8714', type: 'Atendimento social', info: 'psicologiacvbrj@cruzvermelharj.org.br' },
    //    { id: 2, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 2, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 2, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Rio de Janeiro</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default RJ
