import React, { useState } from 'react'
import './UF.css'

const BA = () => {
  const [service] = useState([
    { id: 1, uf: 'BA', city: 'Salvador', name: 'Serviço de Psicologia Prof. João Ignácio de Mendonça (IPS/UFBA)', address: 'PROAE, Rua Caetano Moura, nº 140, Federação', phone: '(71) 98522-8306', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'BA', city: 'Feira de Santana', name: 'Clínica-Escola de Psicologia da Faculdade Anísio Teixeira (FAT)', address: 'Av. João Durval Carneiro, n° 1869 - Ponto Central (Empresarial Rosilda Dantas)', phone: '(75) 3025-6204', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'BA', city: 'Paulo Afonso', name: 'Clínica Escola Prof. Gilberto Gomes de Oliveira (UniRios)', address: 'Rua Riachuelo, nº 37, Centro', phone: '(75) 3501-0771', type: 'Clínica escola', info: 'clinicaescola@unirios.edu.br' },
    { id: 4, uf: 'BA', city: 'Cachoeira', name: 'Clínica Adventista da Bahia', address: '-', phone: '(75) 3425-8021', type: 'Clínica escola', info: 'clinica@adventista.edu.br' },
    //    { id: 5, uf: 'BA', city: 'Irecê', name: 'Clínica Escola de Psicologia (CEP) da Faculdade Irecê (FAI)', address: '', phone: '', type: '', info: '' },
    { id: 6, uf: 'BA', city: 'Salvador', name: 'Clínica-escola de Psicologia da UNINASSAU', address: 'Rua dos Maçons, 364, bairro da Pituba', phone: '-', type: 'Clínica escola', info: '-' },
    { id: 7, uf: 'BA', city: 'Ilhéus', name: 'Atendimento Psicológico da Faculdade de Ilhéus', address: 'Sede do Prédio de Odontologia da Faculdade de Ilhéus', phone: '(73) 2101-1747', type: 'Clínica escola', info: 'clinicapsico@faculdadedeilheus.com.br' },
    //    { id: '', uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Bahia</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default BA
