import React, { useState } from 'react'
import './UF.css'

const ES = () => {
  const [service] = useState([
    { id: 1, uf: 'ES', city: 'Vitória', name: 'Núcleo de Psicologia Aplicada (NPA) da UFES', address: 'Campus Goiabeiras, junto à secretaria integrada do prédio do CEMUNI VI', phone: '(27) 4009-2509', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'ES', city: 'Vitória', name: 'Clínica de Psicologia FAESA', address: 'Bloco 6 - Av. Vitória, 2220 - 1º Andar - Monte Belo', phone: '(27) 2122-4168', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'ES', city: 'Alegre', name: 'Serviço de Psicologia Aplicada (SPA) da FAFIA', address: 'R. Padre Jose Bellotti, 258 - Conceicao', phone: '-', type: 'Clínica escola', info: '-' },
    { id: 4, uf: 'ES', city: 'Vila Velha', name: 'Clínica de Nutrição e Psicologia - UVV', address: 'Avenida José Dantas de Melo, 21 - Boa Vista II', phone: '(27) 3421-2161', type: 'Clínica escola', info: '-' },
    { id: 5, uf: 'ES', city: 'Linhares', name: 'Clínica de Psicologia da Faculdade Pitágoras de Linhares', address: 'Cati - Centro de Atendimento Integrado à Comunidade - bairro Conceição', phone: '3048-1603', type: 'Clínica escola', info: '-' },
    { id: 6, uf: 'ES', city: 'Cachoeiro de Itapemirim', name: 'Clínica de Psicologia do Centro Universitário São Camilo', address: '-', phone: '(28) 3526-5938', type: 'Clínica escola', info: '-' },
    { id: 7, uf: 'ES', city: 'Santa Teresa', name: 'Serviço de Psicologia Aplicada da ESFA', address: 'R. Bernardino Monteiro, 700 - Pinheiros', phone: '(27) 99936-3612', type: 'Clínica escola', info: 'psicologiaaplicada@esfa.edu.br' },
    { id: 8, uf: 'ES', city: 'Serra', name: 'Núcleo de Práticas Psicológicas da Faculdade Multivix Serra (NPP)', address: '-', phone: '(27) 3041-7070', type: 'Clínica escola', info: '-' },
    { id: 9, uf: 'ES', city: 'Vitória', name: 'Centro Integrado de Atenção à Saúde da Comunidade (Ciasc)', address: 'Avenida Vitória', phone: '(27) 3331-8654', type: 'Clínica escola', info: '-' },
    { id: 10, uf: 'ES', city: 'Cariacica', name: 'Clínica serviço-escola de Psicologia “Nair Mendes Tavares” da Faculdade Europeia de Vitória - FAEV', address: 'Rua Adélia Pereira de Souza, nº 06, Itacibá', phone: '(27) 3114-7306', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Espírito Santo</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default ES
