import React, { useState } from 'react'
import './UF.css'

const DF = () => {
  const [service] = useState([
    { id: 1, uf: 'DF', city: 'Brasília', name: 'Centro de Atendimento e Estudos Psicológicos (CAEP)', address: 'Campus Universitário Darcy Ribeiro - Universidade de Brasília', phone: '(61) 3107-1680', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'DF', city: 'Brasília', name: 'Centro de Formação de Psicólogos (Cenfor)', address: 'Edifício União - Quadra 1 do Setor Comercial Sul', phone: '(61) 3966-1626', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'DF', city: 'Brasília', name: 'Serviço de Psicologia João Claudio Todorov', address: 'SGAS QUADRA 613/614, Via L2 Sul - Asa Sul', phone: '(61) 3445-4502', type: 'Clínica escola', info: '-' },
    { id: 4, uf: 'DF', city: 'Ceilândia Norte', name: 'Clínica de Psicologia do IESB Ceilândia', address: 'QNN 31, Áreas Especiais B/C/D/E', phone: '-', type: 'Clínica escola', info: '-' },
    { id: 5, uf: 'DF', city: 'Taguatinga', name: 'Centro de Formação em Psicologia Aplicada (CEFPA)', address: 'Câmpus I - QS 7 - Lote 1 - Pistão Sul. Secretaria do CEFPA - Bloco M - Sala 008.', phone: '(61) 3356-9328 | (61) 99267-0473', type: 'Clínica escola', info: '-' },
    { id: 6, uf: 'DF', city: 'Brasília', name: 'Clínica Escola de Psicologia - UDF', address: 'SGAS 903 Bloco D lote 79, Campus 4R', phone: '(61) 3225-7724 | (61) 99983-7555', type: 'Clínica escola', info: '-' },
    { id: 7, uf: 'DF', city: 'Brasília', name: 'Clínica de Psicologia UNIP Brasília', address: 'SGAS Quadra 913 - s/n - Conjunto B - Bloco H - Térreo - Asa Sul', phone: '(61) 2192-7092', type: 'Clínica escola', info: '-' },
    { id: 8, uf: 'DF', city: 'Brasília', name: 'CAPS BRASILIA II', address: 'SGAN 905 SAP 1 (antiga UBS 11 - Asa Norte)', phone: '(61) 920001-7779 | (61) 92001-7782', type: 'Serviço público', info: '-' },
    { id: 9, uf: 'DF', city: 'Brasília', name: 'CAPSi BRASÍLIA', address: 'SMHN QUADRA 3, conjunto 01, Bloco A, Ed. COMPP, Asa Norte', phone: '(61) 2017-1900, ramais 7710 e 7711', type: 'Serviço público', info: '-' },
    { id: 10, uf: 'DF', city: 'Brasília', name: 'CAPS AD III BRASILIA RODOVIARIA', address: 'SETOR COMERCIAL SUL QUADRA 5 Bloco C Loja 73', phone: '(61) 2017-1900', type: 'Serviço público', info: '-' },
    { id: 11, uf: 'DF', city: 'Taguatinga', name: 'Psic. Matheus Costa dos Santos (CRP 01/25161)', address: 'Espaço Plurais - Edifício Connect Towers - Sala 711', phone: '(61) 98410-4701', type: 'Atendimento psicológico a preço social', info: 'matheuscs.psi@gmail.com' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },

  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Distrito Federal</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default DF
