import React, { useState } from 'react'
import './UF.css'

const SC = () => {
  const [service] = useState([
    { id: 1, uf: 'SC', city: 'Florianópolis', name: 'SAPSI - Serviço de Atenção Psicológica', address: 'Departamento de Psicologia - Centro de Filosofia e Ciências Humanas, bloco D, 2º andar. UFSC - Campus Universitário - Trindade', phone: '(48) 3721-9402 | (48) 3721-4989', type: 'Clínica escola', info: 'sapsi@contato.ufsc.br' },
    { id: 2, uf: 'SC', city: 'Itajaí', name: 'Clínica de Psicologia da Univali', address: 'Sala 201, 2º piso - bloco F2 - Campus Itajaí', phone: '(47) 3341-7586', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'SC', city: 'Balneário Camboriú', name: 'Clínica Escola de Psicologia UniSul', address: 'Campus Balneário Camboriú - Av. Santa Catarina, 151', phone: '-', type: 'Clínica escola', info: '-' },
    { id: 4, uf: 'SC', city: 'Itajaí', name: 'Clínica Escola de Psicologia UniSul', address: 'R. Brusque, 162 - Centro', phone: '(47) 99134-9122', type: 'Clínica escola', info: 'clinicapsicologia.itj@animaeducacao.com.br' },
    { id: 5, uf: 'SC', city: 'Palhoça', name: 'Clínica Escola de Psicologia UniSul', address: 'Campus Pedra Branca - Avenida Pedra Branca, 25 bloco J', phone: '(48) 3279-1083', type: 'Clínica escola', info: 'servico.psicologia@unisul.br' },
    { id: 6, uf: 'SC', city: 'Florianópolis', name: 'Clínica Escola de Psicologia UniSul', address: 'Campus Centro - Rua Trajano, 219 - Centro', phone: '(48) 3279-0954', type: 'Clínica escola', info: 'servico.psicologiafp@unisul.br' },
    { id: 7, uf: 'SC', city: 'Florianópolis', name: 'Clínica Escola de Psicologia UniSul', address: 'Campus Continente - Av. Governador Ivo Silveira 3775 - Capoeiras', phone: '-', type: 'Clínica escola', info: 'clinicadepsicologia.continente @animaeducacao.com.br' },
  //  { id: 8, uf: 'SC', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Santa Catarina</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default SC
