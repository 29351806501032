import React, { useState } from 'react'
import './UF.css'

const PB = () => {
  const [service] = useState([
    { id: 1, uf: 'PB', city: 'João Pessoa', name: 'Clínica-Escola de Psicologia da UFPB', address: 'Via Expressa Padre Zé, 289-639 - Conj. Pres. Castelo Branco III', phone: '(83) 3216-7338', type: 'Clínica escola', info: '-' },
    //    { id: 1, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 2, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Paraíba</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default PB
