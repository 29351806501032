import React, { useState } from 'react'
import './UF.css'

const MS = () => {
  const [service] = useState([
    { id: 1, uf: 'MS', city: 'Campo Grande', name: 'Serviço Escola de Psicologia (SEP) da UFMS', address: 'Av. Sen. Filinto Müler, 1 - Vila Ipiranga', phone: '(67) 3345-7802 | (67) 3345-7884 (WhatsApp)', type: 'Clínica escola', info: 'uap-clips.fach@ufms.br' },
    { id: 2, uf: 'MT', city: 'Paranaíba', name: 'Clínica-Escola de Psicologia “Carolina Martuscelli Bori”', address: 'Avenida Pedro Pedrossian, N° 725, Bairro Universitário - UFMS', phone: '(67) 3669-0117 | (67) 3669-0100', type: 'Clínica escola', info: '-' },
    //    { id: 2, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Mato Grosso do Sul</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default MS
