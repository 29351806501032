import React, { useState } from 'react'
import './UF.css'

const PR = () => {
  const [service] = useState([
    { id: 1, uf: 'PR', city: 'Curitiba', name: 'Centro de Psicologia Aplicada (CPA) da UFPR', address: 'Praça Santos Andrade, 50 - 1º andar - sala 112', phone: '(41) 3310-2614', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'PR', city: 'Curitiba', name: 'Clínica Escola de Psicologia Tatiana Forte', address: 'Av. Iguaçu, 471 - Rebouças', phone: '(41) 3310-1515', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'PR', city: 'Curitiba', name: 'Núcleo de Práticas em Psicologia (NPP) da PUCPR', address: 'Rua Rockefeller, 1450 - Rebouças - Pavilhão Pereira Lemos', phone: '(41) 3271-1663 | (41) 99661-6452 (WhatsApp)', type: 'Clínica escola', info: 'psiclin@pucpr.br' },
    { id: 4, uf: 'PR', city: 'Curitiba', name: 'Clínica-Escola e Serviços de Psicologia da Tuiuti', address: 'Rua Sidney Antonio Rangel Santos, nº 245 - Santo Inácio', phone: '(41) 3331-7846 | (41) 3331-7836', type: 'Clínica escola', info: '-' },
    { id: 5, uf: 'PR', city: 'Curitiba', name: 'Clínica-Escola de Psicologia do UNICURITIBA', address: 'R. Des. Westphalen, 2005 - Centro', phone: '-', type: 'Clínica escola', info: 'agendepsico@unicuritiba.edu.br' },
    { id: 6, uf: 'PR', city: 'Irati', name: 'Clínica-Escola de Psicologia - CEPSICO', address: '-', phone: '(42) 3421-3226/3227 | (42) 99128-9980', type: 'Clínica escola', info: '-' },
    { id: 7, uf: 'PR', city: 'Curitiba', name: 'Centro de Psicologia da Universidade Positivo', address: 'Térreo do Bloco Amarelo da Universidade Positivo - Campus Sede - Ecoville', phone: '(41) 3317-3059 | (41) 99981-0177 (WhatsApp)', type: 'Clínica escola', info: 'centrodepsicologia@up.edu.br' },
    { id: 8, uf: 'PR', city: 'Apucarana', name: 'Clínica Escola de Fisioterapia, Nutrição e Psicologia da FAP (Faculdade de Apucarana)', address: 'Osvaldo de Oliveira, 600 Jd. Flamingos', phone: '(43) 3033-8900/8945', type: 'Clínica escola', info: 'clinicaescola@fap.com.br' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Paraná</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default PR
