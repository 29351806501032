import React, { useState } from 'react'
import './UF.css'

const RO = () => {
  const [service] = useState([
    { id: 1, uf: 'RO', city: 'Porto Velho', name: 'Serviço de Psicologia Aplicada (SPA) da UNIR', address: 'Avenida Presidente Dutra, Nº. 2965, Centro', phone: '(69) 2182-2025', type: 'Clínica escola', info: 'spa@unir.br' },
    { id: 2, uf: 'RO', city: 'Porto Velho', name: 'Clínica Escola da FIMCA', address: 'Rua das Ararás - Eldorado', phone: '(69) 3217-8938 | (69) 3226-9589', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'RO', city: 'Porto Velho', name: 'Associação Dr. Aparício Carvalho de Moraes', address: 'Rua dos Andrades, no 10277 - Mariana', phone: '(69) 3217-8938 | (69) 3226-9589', type: 'Clínica escola', info: 'Vinculada à FIMCA' },
    { id: 4, uf: 'RO', city: 'Porto Velho', name: 'Serviço de Psicologia Aplicada (SPA) da São Lucas', address: 'Bloco Murici, Campus 1 do Centro Universitário São Lucas', phone: '(69) 3211-8027', type: 'Clínica escola', info: '-' },
    { id: 5, uf: 'RO', city: 'Ariquemes', name: 'Núcleo de Psicologia da IESUR', address: 'Av. Capitão Silvio, 2738 - Setor Grandes Áreas', phone: '(69) 3535-5005 | (69) 3535-5008', type: 'Clínica escola', info: '-' },
    { id: 6, uf: 'RO', city: 'Cacoal', name: 'Clínica-Escola de Psicologia da UNINASSAU', address: 'Unidade I da UNINASSAU - Avenida Rosilene Transpadini, 2070, Bairro Jardim Eldorado', phone: '(69) 99607-1656', type: 'Clínica escola', info: '-' },
    { id: 7, uf: 'RO', city: 'Vilhena', name: 'Clínica Escola de Psicologia da FAMA', address: 'Faculdade da Amazônia - Rua Wallisson Junior Arrigo, 2043 - Bairro Cristo Rei', phone: '(69) 99293-2693', type: 'Clínica escola', info: '-' },
    { id: 8, uf: 'RO', city: 'Cacoal', name: 'Clínica-Escola de Psicologia da UNESC', address: 'Rua dos Esportes, 1038, bairro Incra', phone: '(69) 3441-4503 | (69) 99237-2101', type: 'Clínica escola', info: '-' },
    { id: 9, uf: 'RO', city: 'Ariquemes', name: 'Serviço Escola de Psicologia da UNIFAEMA', address: 'Instituto UNIFAEMA - Avenida Machadinho, 4349, área de expansão urbana (setor 06)', phone: '(69) 3536-4305 | (69) 99322-9807', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Rondônia</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default RO
