import React, { useState } from 'react'
import './UF.css'

const AO = () => {
  const [service] = useState([
    { id: 1, uf: '-', city: '-', name: 'CVV - Centro de Valorização da Vida', address: '-', phone: '188 (24 horas)', type: 'Atendimento Online', info: 'www.cvv.org.br' },
    { id: 2, uf: '-', city: '-', name: 'Pode Falar', address: 'podefalar.org.br', phone: '(61) 9660-8843 (WhatsApp)', type: 'Atendimento Online', info: 'Realização: UNICEF | Faixa etária: 13 a 24 anos' },
    { id: 3, uf: 'PB', city: 'João Pessoa', name: 'Plantão Psicológico NAEPSI', address: 'Via Expressa Padre Zé, 289-639 - Conj. Pres. Castelo Branco III', phone: '(83) 98212-4937 (WhatsApp)', type: 'Clínica escola | Atendimento Online', info: 'Clínica-Escola de Psicologia da UFPB' },
    { id: 4, uf: 'RJ', city: 'Rio de Janeiro', name: 'Clínica de Psicologia Social', address: 'Cruz Vermelha Brasileira - Praça da Cruz Vermelha 10/12 - Centro', phone: '(21) 2508-9090 ou ramal 8714', type: 'Atendimento social | Atendimento online', info: 'psicologiacvbrj@cruzvermelharj.org.br' },
    //    { id: 1, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  return (
    <div id="Board">
      <h3>Atendimento Online</h3>
      <p>
      <ul>
        {service.map((item) => (
          <li key={item.id}>
            <strong>{item.name}</strong><br />
            <strong>UF: </strong>{item.uf}<br />
            <strong>Cidade: </strong>{item.city}<br />
            <strong>Endereço: </strong>{item.address}<br />
            <strong>Telefone: </strong>{item.phone}<br />
            <strong>Tipo: </strong>{item.type}<br />
            <strong>Mais Informações: </strong>{item.info}<br />
            <hr />
          </li>
        ))}
      </ul>
      </p>
    </div>
  )
}

export default AO
