import React, { useState } from 'react'
import './UF.css'

const AL = () => {
  const [service] = useState([
    { id: 1, uf: 'AL', city: 'Maceió', name: 'Serviço de Psicologia Aplicada (SPA)', address: 'Instituto de Psicologia do Campus A. C. Simões - Av. Lourival Melo Mota, s/n, Tabuleiro dos Martins', phone: '(82) 3214-1344', type: 'Clínica escola', info: '-' },
    { id: 2, uf: 'AL', city: 'Maceió', name: 'Clínica de Psicologia Aplicada (CPA)', address: 'Unit - R. B, 32 - Cruz das Almas', phone: '(82) 3311-3139', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Alagoas</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default AL
