import React, { useState } from 'react'
import './UF.css'

const RS = () => {
  const [service] = useState([
    { id: 1, uf: 'RS', city: 'Porto Alegre', name: 'Clínica de Atendimento Psicológico da UFRGS', address: 'Av. Protásio Alves, 297 - 3º andar (entrada pela rua São Manoel)', phone: '(51) 3308-2024', type: 'Clínica escola', info: 'clinicap@ufrgs.br' },
    { id: 2, uf: 'RS', city: 'Canoas', name: 'Clínica-Escola de Psicologia da Ulbra', address: 'Av. Farroupilha, 8001 - prédio 22 - 2º andar - ao lado do Hospital Universitário', phone: '(51) 3477-9269', type: 'Clínica escola', info: '-' },
    { id: 3, uf: 'RS', city: 'Porto Alegre', name: 'Serviço de Atendimento e Pesquisa em Psicologia/SAPP da PUCRS', address: 'Av. Ipiranga 6681 - Prédio 11 - sala 209', phone: '(51) 3320-3561 | (51) 98443-0788 (WhatsApp)', type: 'Clínica escola', info: 'sapp@pucrs.br' },
    { id: 4, uf: 'RS', city: 'Novo Hamburgo', name: 'Clínica-Escola do curso de PSICOLOGIA IENH', address: 'Faculdade IENH, entrada pela Rua Agudo, 85, bairro Hamburgo Velho', phone: '(51) 3594-3022', type: 'Clínica escola', info: 'clinicaescola@ienh.com.br' },
    { id: 5, uf: 'RS', city: 'Bagé', name: 'Clínica Escola de Psicologia (CEPsi)', address: 'Faculdade IDEAU', phone: '-', type: 'Clínica escola', info: '-' },
    { id: 6, uf: 'RS', city: 'Passo Fundo', name: 'Clínica-Escola de Psicologia da Universidade de Passo Fundo (UPF)', address: 'Clínica escola', phone: '(54) 3316-8330', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Rio Grande do Sul</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default RS
