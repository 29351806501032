import React, { useState } from 'react'
import './UF.css'

const CE = () => {
  const [service] = useState([
    { id: 1, uf: 'CE', city: 'Fortaleza', name: 'Clínica-escola de Psicologia da Universidade Federal do Ceará (UFC)', address: 'Rua Waldery Uchoa, n° 3-A', phone: '(85) 3366-7690 | (85) 3366-7689', type: 'Clínica escola', info: 'ufc.clinicapsi@gmail.com' },
    { id: 2, uf: 'CE', city: 'Fortaleza', name: 'Clínica Escola de Psicologia do Centro Universitário Estácio do Ceará', address: 'Rua Eliseu Uchôa Beco, 600, Patriolino Ribeiro', phone: '(85) 3270-6798', type: 'Clínica escola', info: 'Unidade Via Corpvs' },
    { id: 3, uf: 'CE', city: 'Fortaleza', name: 'Serviço de Psicologia Aplicada (SPA) da UNIFOR', address: 'Rua Desembargador Floriano Benevides, 221, 2º andar, bairro Edson Queiroz - ao lado do Fórum Clóvis Beviláqua', phone: '(85) 3477-3643 | (85) 3477-3644 | (85) 99210-2924', type: 'Clínica escola', info: 'namispa@unifor.br' },
    { id: 4, uf: 'CE', city: 'Fortaleza', name: 'Clínica Integrada de Saúde Unifametro', address: 'Rua Liberato Barroso, nº 1503, Centro', phone: '(85) 3206-6433', type: 'Clínica escola', info: '-' },
    { id: 5, uf: 'CE', city: 'Fortaleza', name: 'Serviço Integrado de Psicologia Aplicada (SIP) da Faculdade Ari de Sá', address: 'Rua João Cordeiro, 1904 - Centro', phone: '(85) 99213-0812', type: 'Clínica escola', info: '-' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
    //    { id: 0, uf: '', city: '', name: '', address: '', phone: '', type: '', info: '' },
  ]);

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredService = service.filter((item) =>
    item.city.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div id="Board">
      <p id='showResult'>
        Aqui estão os serviços encontrados para o estado selecionado.
        <br />
        Se preferir, use o campo abaixo para filtrar os resultados por cidade.
        <br /><br />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Digite o nome da cidade" />
      </p>
      <h3>Ceará</h3>
      <p>
        <ul>
          {filteredService.map((item) => (
            <li key={item.id}>
              <strong>{item.name}</strong><br />
              <strong>UF: </strong>{item.uf}<br />
              <strong>Cidade: </strong>{item.city}<br />
              <strong>Endereço: </strong>{item.address}<br />
              <strong>Telefone: </strong>{item.phone}<br />
              <strong>Tipo: </strong>{item.type}<br />
              <strong>Mais Informações: </strong>{item.info}<br />
              <hr />
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default CE
